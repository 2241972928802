import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "p-inputgroup" }
const _hoisted_3 = {
  class: "p-inputgroup-addon font-semibold general-label-color",
  style: { width: '112px' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "grid m-0 col-12 md:col-6 xl:col-4",
        key: field.field_no
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("small", _hoisted_3, _toDisplayString(field.display_name), 1),
          (field.conv && field.conv[0] === 'D')
            ? (_openBlock(), _createBlock(_component_Calendar, {
                key: 0,
                modelValue: _ctx.customFieldValues[field.field_no].inputValue,
                "onUpdate:modelValue": ($event: any) => ((_ctx.customFieldValues[field.field_no].inputValue) = $event),
                showIcon: true,
                dateFormat: "mm-dd-yy",
                inputClass: "text-lg",
                onDateSelect: ($event: any) => (_ctx.selectCustomField($event, field))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onDateSelect"]))
            : (
            field.valid_value_items &&
            field.valid_value_items.length > 0 &&
            field.multi_line === 'Y'
          )
              ? (_openBlock(), _createBlock(_component_MultiSelect, {
                  key: 1,
                  modelValue: _ctx.customFieldValues[field.field_no].inputValue,
                  "onUpdate:modelValue": ($event: any) => ((_ctx.customFieldValues[field.field_no].inputValue) = $event),
                  options: field.valid_value_items,
                  optionLabel: "valid_desc",
                  optionValue: "valid_value",
                  placeholder: `Select ${field.display_name}`
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
              : (
            field.valid_value_items && field.valid_value_items.length > 0
          )
                ? (_openBlock(), _createBlock(_component_Dropdown, {
                    key: 2,
                    modelValue: _ctx.customFieldValues[field.field_no].inputValue,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.customFieldValues[field.field_no].inputValue) = $event),
                    options: field.valid_value_items,
                    optionLabel: "valid_desc",
                    optionValue: "valid_value",
                    placeholder: `Select ${field.display_name}`
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder"]))
                : (_openBlock(), _createBlock(_component_InputText, {
                    key: 3,
                    type: "text",
                    modelValue: _ctx.customFieldValues[field.field_no].inputValue,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.customFieldValues[field.field_no].inputValue) = $event),
                    class: "text-lg w-full"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
        ])
      ]))
    }), 128))
  ]))
}