
import { defineComponent } from "vue";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { RepItem } from "@/types/customer";
import Dialog from "primevue/dialog";
import InputNumber from "primevue/inputnumber";
import InputText from "primevue/inputtext";

import SearchFile from "@/components/SearchFile.vue";

export default defineComponent({
  props: {
    reps: Array,
  },
  name: "RepDataTable",
  components: {
    Button,
    DataTable,
    Column,
    Dialog,
    InputNumber,
    SearchFile,
    InputText,
  },

  data() {
    return {
      rep_items: this.reps || [],
      rep: {} as RepItem,
      editRepDialog: false,
      deleteRepDialog: false,
    };
  },
  methods: {
    confirmDeleteRep(rep: RepItem) {
      this.rep = rep;
      this.deleteRepDialog = true;
    },
    deleteRep() {
      const newList = this.rep_items.filter(
        (item: any) => item.rep != this.rep.rep,
      );
      this.rep_items = newList;

      this.deleteRepDialog = false;
      this.$emit("update-reps", this.rep_items);
    },
    editRep(rep: RepItem) {
      this.rep = { ...rep };
      this.editRepDialog = true;
    },
    updateRep(rep: any) {
      this.rep = {
        rep: rep.rep_id,
        name: rep.name || "",
        order_pct: "",
      } as any;
    },
    saveRep() {
      const found: any = this.rep_items.find((item: any) => {
        return item.rep == this.rep.rep;
      });

      if (found) {
        found.order_pct = (this.rep as any).order_pct.toString();
      } else {
        this.rep_items.push({
          rep: this.rep.rep,
          order_pct: (this.rep as any).order_pct.toString(),
        });
      }
      this.editRepDialog = false;

      this.$emit("update-reps", this.rep_items);
    },
    closeEditModal() {
      this.editRepDialog = false;
    },
    addNewRep() {
      this.rep = {} as RepItem;
      this.editRepDialog = true;
    },
  },
  watch: {
    reps: {
      immediate: true,
      handler(val) {
        this.rep_items = val;
      },
    },
  },
});
