import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pos = _resolveComponent("Pos")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    modal: "",
    closeOnScape: true,
    closable: false,
    class: "search-part-modal w-10",
    onHide: _ctx.closeDialog
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Close",
        class: "m-0 w-4 md:w-2 lg:w-1",
        onClick: _ctx.closeDialog
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Pos, {
        onAddPart: _ctx.addItem,
        companyCode: _ctx.companyCode
      }, null, 8, ["onAddPart", "companyCode"])
    ]),
    _: 1
  }, 8, ["visible", "onHide"]))
}