
import { defineComponent } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Pos from "@/components/Pos/Pos.vue";

export default defineComponent({
  name: "SearchPartDialog",

  components: {
    Dialog,
    Button,
    Pos,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    companyCode: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    visible: false,
  }),
  created() {
    this.visible = this.show;
  },

  emits: ["close-dialog", "addToCart"],

  methods: {
    addItem(data: any) {
      this.$emit("addToCart", data);
    },
    closeDialog() {
      this.$emit("close-dialog", false);
      this.visible = false;
    },
  },

  watch: {
    show() {
      this.visible = this.show;
    },
  },
});
