
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import store from "@/store";
import Button from "primevue/button";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Dialog from "primevue/dialog";

// Services
import CustomerService from "@/services/CustomerService";
import RepService from "@/services/RepService";

const repService = new RepService();
const customerService = new CustomerService();
import { mapActions, mapGetters, mapState } from "vuex";

export default defineComponent({
  name: "SearchFile",
  components: {
    InputText,
    Button,
    DataTable,
    Dialog,
    Column,
  },
  computed: {
    ...mapState(["customerInquiry"]),
    ...mapGetters({
      getAllCustomers: "customer/getAllCusts",
      getFilter: "session/getFilter",
      getSelectedCoCode: "customerInquiry/getSelectedCoCode",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllCustomers: "customer/getAllCustomers",
      fetchCustomer: "customerInquiry/getCustomer",
      getMrkControl: "mrkControl/getMrkControl",
      addNotification: "notification/add",
    }),
    searchFile() {
      switch (this.file) {
        case "CUST":
          this.findCustomer();
          break;
        case "REP":
          this.findRep();
          break;
        default:
          break;
      }
    },
    async findCustomer() {
      let filter_inactive_customers = "";
      if (this.getSelectedCoCode) {
        const data = await this.getMrkControl({
          client: store.getters["session/getClient"],
          selectedCode: this.getSelectedCoCode,
          refresh: false,
        });
        filter_inactive_customers = data.filter_inactive_customers || "";
      }

      const filterCustomers = filter_inactive_customers === "Y" ? "Y" : "";
      const reps = this.getFilter("CUST", "REP");

      customerService
        .getAllCustomers({
          selection: this.input,
          correls: "rep_name email_address",
          rep: reps,
          activeOnly: filterCustomers,
        })
        .then((response: any) => {
          this.options = response.cust_items.sort((a: any, b: any) =>
            a.name.localeCompare(b.name),
          );
          if (this.options.length === 1) {
            this.$emit("selectOption", this.options[0]);
          } else {
            this.showOptions = true;
          }
        });
    },
    findRep() {
      repService
        .getReps({
          search: this.input,
          active: true,
        })
        .then((response: any) => {
          this.options = response.rep_items;
          if (this.options.length === 0) {
            this.addNotification({
              type: "error",
              message: "No REPs found",
            });
          } else if (this.options.length === 1) {
            this.$emit("selectOption", this.options[0]);
          } else {
            this.showOptions = true;
          }
        });
    },
    selectOption() {
      this.$emit("selectOption", this.selectedData);
      if (
        this.selectedData &&
        (this.selectedData as any)[this.field_id as string]
      ) {
        this.input = (this.selectedData as any)[this.field_id as string];
      }
      this.input = (this.selectedData as any)[this.field_id as string];
      this.showOptions = false;
    },
  },
  data() {
    return {
      input: this.inputValue,
      options: [],
      selectedData: null,
      showOptions: false,
    };
  },
  props: {
    inputValue: String,
    field_id: String,
    file: String,
    placeHolder: String,
    leftIcon: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    inputValue: {
      immediate: true,
      handler(val) {
        this.input = val;
      },
    },
  },
});
