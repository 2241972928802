
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// Components
import AutoComplete from "primevue/autocomplete";

// Services
import ContactService from "@/services/ContactService";
const contactService = new ContactService(process.env.VUE_APP_ABSTRACTION_API);

// Types
interface Data {
  query: string;
  suggestions: string[];
}

export default defineComponent({
  components: {
    AutoComplete,
  },
  emits: ["item-select"],
  props: {
    selectedId: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customerContacts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data(): Data {
    return {
      query: "",
      suggestions: [],
    };
  },
  watch: {
    selectedId() {
      this.getContactById();
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    showDropDown(): boolean {
      return this.customerContacts.length > 0;
    },
    contactsList(): any {
      const list = [...this.customerContacts, ...this.suggestions];
      return list.sort((a, b) => {
        const nameA = this.getFullname(a);
        const nameB = this.getFullname(b);
        if (nameA && nameB) {
          return nameA.localeCompare(nameB);
        } else {
          return 0;
        }
      });
    },
  },
  methods: {
    handleComplete() {
      // Call API for contact search terms
      if (this.query) {
        contactService
          .searchContacts(this.query, this.getClient)
          .then((response: any) => {
            this.suggestions = response.contact_items;
          });
      } else {
        this.suggestions = [];
      }
    },
    handleItemSelect(event: any) {
      this.$emit("item-select", event.value);
    },
    getFullname(contact: any) {
      let fullname = contact.first_name;
      if (contact.last_name) {
        fullname += " " + contact.last_name;
      }
      return fullname;
    },
    getContactById() {
      if (this.selectedId) {
        contactService
          .searchContacts(this.selectedId, this.getClient)
          .then((response: any) => {
            if (response.contact_items.length) {
              const contact = response.contact_items[0];
              this.query = contact.first_name;
              if (contact.last_name) {
                this.query += " " + contact.last_name;
              }
            }
          });
      }
    },
  },
  mounted() {
    // Preset Selected Customer From Filter
    this.getContactById();
  },
});
