import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Accordion = _resolveComponent("Accordion")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Accordion, {
      multiple: true,
      activeIndex: _ctx.activeIndex as unknown as [Number],
      class: "custom-accordion"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "accordion-content")
      ]),
      _: 3
    }, 8, ["activeIndex"])
  ]))
}