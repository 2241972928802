
import { defineComponent } from "vue";
import Utils from "@/utility/utils";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import Tag from "primevue/tag";
import Card from "primevue/card";
import ProductDialog from "@/components/Pos/ProductDialog.vue";
import Paginator from "primevue/paginator";
import { mapGetters } from "vuex";
import { FDICT_PARTS } from "@/utility/fdicts/parts";

export default defineComponent({
  name: "ResultGridView",
  components: {
    Button,
    InputNumber,
    ProductDialog,
    Tag,
    Card,
    Paginator,
  },
  props: {
    scrollHeight: {
      type: String,
      default: "50vh",
    },
    productList: Array,
    partIdFields: {
      type: Array,
      default: () => [] as any[],
    },
    rows: {
      type: Number,
      default: 25,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => [25, 50, 75],
    },
    first: {
      type: Number,
      default: 0,
    },
    showPaginator: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["addPartToOrder", "update:rows", "onPage"],
  created() {
    this.rowsPerPage = this.rows;
    this.pageOptions = this.itemsPerPageOptions as any;
    this.firstItem = this.first;
  },
  data() {
    return {
      showProductDetails: false,
      selectedProduct: {} as any,
      pageOptions: [25, 50, 75],
      rowsPerPage: 25,
      firstItem: 0,
    };
  },
  computed: {
    ...mapGetters({
      getCustomer: "pos/getCustomer",
      getFieldLabel: "fdict/getFieldLabel",
    }),
    getTotalRecords(): number {
      return this.productList ? this.productList.length : 0;
    },
    getProductsToDisplay(): any[] {
      return this.productList
        ? this.productList.slice(
            this.firstItem,
            this.firstItem + this.rowsPerPage,
          )
        : [];
    },
    partNumberLabel(): string {
      return this.getFieldLabel("PARTS", FDICT_PARTS.PART_NO, "Part #");
    },
  },
  methods: {
    scrollToFirstItem() {
      setTimeout(() => {
        const grid = document.getElementById("grid");

        if (grid) {
          const firstItemElement = grid.querySelector(
            ".product-item:first-child",
          );

          if (firstItemElement) {
            firstItemElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }, 10);
    },
    onPage(event: any) {
      this.firstItem = event.first;
      this.scrollToFirstItem();
      this.$emit("onPage", event);
    },
    onQuantityInputClick(event: any) {
      event.stopPropagation();
    },
    useFractionalQuantities(part: any) {
      return part.fractions === "Y";
    },
    handleDecimals(event: any, part: any) {
      if (this.useFractionalQuantities(part)) {
        Utils.handleLeadingDecimal(event, (value: any) => {
          part.quantity = value;
        });
      }
    },
    handleOnHandTagText(availQty: number) {
      if (availQty > 0) {
        return `${availQty}`;
      } else {
        return "Out of Stock";
      }
    },
    handleOnHandTagColor(availQty: number) {
      return availQty > 0 ? "success" : "danger";
    },
    formatPrice(amount: number) {
      return Utils.formatPrice(amount);
    },
    onElemClick(event: any) {
      this.selectedProduct = event;
      this.showProductDetails = true;
    },
    closeDialog() {
      this.showProductDetails = false;
    },
    addItem(item: any) {
      this.$emit("addPartToOrder", item);
    },
    calculateStep(part: any) {
      if (part.sell_qty) {
        return parseFloat(part.sell_qty);
      }
      return 1;
    },
  },
});
