export default interface PriceControlState {
  code_items: Array<PriceCodeItem>;
  control_id: string;
}

export interface PriceCodeItem {
  code?: string;
  description?: string;
  lock_price?: string;
}

export enum SellAsTypes {
  ADD_TO_NEW_LINE = "new"
}
