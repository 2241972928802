
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

// Components
import AutoComplete from "primevue/autocomplete";
import CustomerService from "@/services/CustomerService";
import Customer from "@/types/customer";

const customerService = new CustomerService();

// Types
interface Data {
  query: string;
  suggestions: string[];
}

export default defineComponent({
  name: "SearchCustomer",
  components: {
    AutoComplete,
  },
  emits: ["item-select"],
  props: {
    selectedId: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputValue: {
      type: String,
      required: false,
      default: "",
    },
  },
  data(): Data {
    return {
      query: this.inputValue,
      suggestions: [],
    };
  },
  watch: {
    selectedId() {
      this.getCustomerById();
    },
    inputValue: {
      handler: function (val) {
        this.query = val;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
    }),
    suggestionLabels(): any[] {
      return (this.suggestions as any).map(
        (suggestion: Customer) => suggestion.name,
      );
    },
  },
  methods: {
    handleComplete() {
      customerService.fetchCustOptions(this.query).then((response: any) => {
        this.suggestions = response.cust_items || ([] as Array<Customer>);
        if (this.suggestions.length === 1) {
          this.query = (this.suggestions[0] as any).cust_id;
          this.$emit("item-select", this.suggestions[0]);
        }
      });
    },
    handleItemSelect(event: any) {
      const selected: any = this.suggestions.find(
        (suggestion: any) => suggestion.name === event.value,
      );
      this.query = selected.cust_id;
      this.$emit("item-select", selected);
    },
    getCustomerById() {
      if (this.selectedId) {
        customerService
          .getCustomer(this.selectedId, this.getClient, "")
          .then((response: any) => {
            if (response) {
              this.query = response.cust_id;
            }
          });
      }
    },
  },
});
