
import { defineComponent } from "vue";
import store from "@/store";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { FilterMatchMode, FilterOperator } from "primevue/api";
export default defineComponent({
  name: "DataGrid",
  components: {
    DataTable,
    Column,
  },
  mounted() {
    this.firstRow = store.getters[`${this.firstRowGetter}`];
  },
  props: {
    items: Array,
    columns: Array,
    breakpoint: String,
    dataKey: String,
    expansion: Boolean,
    expansionName: String,
    firstRowGetter: String,
  },

  emits: ["onPageClicked", "delete", "select-row", "sort-data"],
  methods: {
    deleteRow(event: any) {
      this.$emit("delete", event.target.id);
    },
    rowClick(event: any) {
      this.$emit("select-row", event);
    },
    sortData(event: any) {
      this.$emit("sort-data", event);
    },
    onPageClicked(event: any) {
      this.$emit("onPageClicked", event);
    },
  },
  data() {
    return {
      rows: 10,
      selectedRow: null,
      firstRow: 0,
      expandedRows: [],
      filters1: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      filters2: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
    };
  },
});
