import ApiService from "./ApiService";

export default class LookupService extends ApiService {
  constructor() {
    super();
  }

  async fetchLookupData(lookupRequest: {
    file?: string;
    recordId?: string;
    lookupName?: string;
    parameters?: any;
    Client?: string;
  }) {
    const response = await this.Post("lookup/doLookup", lookupRequest);
    return response;
  }
}
