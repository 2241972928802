
import { defineComponent, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Lookup from "@/types/lookup";
import LookupService from "@/services/LookupService";
import Utils from "@/utility/utils";
import { Field } from "@/types/fdict";

const lookupService = new LookupService();

export default defineComponent({
  components: {
    DataTable,
    Column,
  },
  props: {
    lookup: {
      type: Object as PropType<Lookup>,
      required: true,
    },
    params: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  data() {
    return {
      data: [] as any[],
      columns: [] as any[],
      loading: false,
    };
  },
  async created() {
    this.loading = true;

    try {
      const lookupData = await lookupService.fetchLookupData({
        lookupName: this.lookup.lookupName,
        recordId: this.lookup.recordId,
        file: this.lookup.file,
        parameters: this.params,
      });
      this.data = lookupData.data;
      this.columns = lookupData.fDicts.map((field: Field) => ({
        ...field,
        display_name: Utils.formatDictionaryName(field.dict_name),
      }));
    } finally {
      this.loading = false;
    }
  },
});
