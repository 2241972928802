
import { defineComponent } from "vue";
import Utils from "@/utility/utils";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { Field } from "@/types/fdict";
export default defineComponent({
  name: "CustomFields",
  components: {
    Calendar,
    InputText,
    Dropdown,
  },

  props: {
    customFields: {
      type: Array as () => Field[],
      required: true,
    },
    data: Object,
    readOnly: Boolean,
  },
  created() {
    this.setData();
  },
  mounted() {
    this.setData();
  },
  emits: ["updateCustomFields"],
  methods: {
    setData() {
      const custom_fields = JSON.parse(
        JSON.stringify(this.data?.custom_fields || {}),
      );
      this.customFields.forEach((field: Field) => {
        this.customFieldValues[field.field_no] = {
          inputValue: this.data
            ? custom_fields[field.json_name as any] ||
              (this.data[field.json_name as any] ?? "")
            : "",
          inputName: field.json_name,
        };
      });
    },
    selectCustomField(data: any, field: Field) {
      if (field.conv && field.conv[0] === "D") {
        this.customFieldValues[field.field_no].inputValue =
          Utils.formatDate(data);
      } else {
        this.customFieldValues[field.field_no].inputValue = data;
      }
    },
  },
  data() {
    return {
      customFieldValues: {} as any,
    };
  },
  watch: {
    customFieldValues: {
      handler: function () {
        const copy = { ...this.data };

        const values = {} as any;
        Object.keys(this.customFieldValues).map((field: any) => {
          const obj = this.customFieldValues[field];
          copy[obj.inputName] = obj.inputValue;
          values[obj.inputName] = obj.inputValue;
        });
        this.$emit("updateCustomFields", values);
      },
      deep: true,
    },
  },
});
